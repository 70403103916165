import { LitElement, css, html, nothing } from 'lit'
import {
  atBreakpoint,
  brandonGrotesqueText,
  elevated,
  interactiveObject,
  interactivePseudoClasses,
  sentenceCase
} from '#js/components/styles'
import { getUrlWithTracking } from '#js/components/utm'

/**
 * A button element that can be used to trigger an action or navigate to a new page.
 */
export class Button extends LitElement {
  static properties = {
    type: { type: String },
    name: { type: String },
    value: { type: String },
    disabled: { type: Boolean },
    class: { type: String },
    btnStyle: { type: String },
    href: { type: URL },
    target: { type: String }
  }

  static styles = css`

    ${atBreakpoint('mobile', css`
      .pagination-button {
        max-width: 34px;
        max-height: 34px;
      }
      :host{
        width:100%;
      }
    `)}

    a, button {
      ${brandonGrotesqueText}
      ${interactiveObject}
      ${sentenceCase}
      border: none;
      appearance: none;
      text-decoration: none;
      background: none;
      display: inline-block;
      height: fit-content;
      box-sizing: border-box;
      border-radius: 100vh;
      width: 100%;
      font-size: 1em;
      font-weight: bold;
      text-align: center;
      line-height: 1em;
      padding: 0.75em 1em;
      white-space: nowrap;
      color: var(--interactive-color);

      &.pagination-button {
        width: 40px;
        height: 40px;
        color: var(--interactive-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.elevated {
        ${elevated}
        background-color: var(--white);
        --elevation-shadow-color: var(--interactive-shadow-color);
      }

      &.filled {
        background-color: var(--interactive-color);
        color: white;

        ${interactivePseudoClasses(css`
          outline-color: var(--interactive-color);
        `)}
      }

      &.tonal {
        background-color: var(--interactive-tonal-color);
        color: var(--black-87);

        ${interactivePseudoClasses(css`
          color: var(--black-87);
        `)}
      }

      &.outlined {
        border: 0.1em solid var(--interactive-color);
        color: var(--interactive-color);
        padding: 0.65em 0.9em;
      }

      &.round {
        padding: 0.35em;
        border-radius: 100vh;
        aspect-ratio: 1;
        width: auto;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  `

  render () {
    if (this.href) {
      return html`
        <a href=${this.href}
           class=${this.class || nothing}
           style=${this.btnStyle || nothing}
           target=${this.target || '_self'}>
          <slot></slot>
        </a>
      `
    }
    return html`
      <button @click=${this.onClick.bind(this)}
              type=${this.type || nothing}
              name=${this.name || nothing}
              value=${this.value || nothing}
              class=${this.class || nothing}
              style=${this.btnStyle || nothing}
              ?disabled=${this.disabled}>
        <slot></slot>
      </button>
    `
  }

  onClick (event) {
    const form = this.closest('form')
    if (form) {
      this.shadowRoot.querySelector('button').disabled = true
      if (this.type === 'submit') {
        if (form.hasAttribute('hx-post')) {
          form.dispatchEvent(new Event('submit'))
        } else {
          form.submit()
        }
      }
      if (this.type === 'reset') {
        if (form.hasAttribute('hx-post')) {
          form.dispatchEvent(new Event('reset'))
        } else {
          form.reset()
        }
      }
    }
  }
}

window.customElements.define('ui-button', Button)

class Share extends LitElement {
  static properties = {
    title: { type: String },
    text: { type: String },
    url: { type: URL }
  }

  static styles = css`
    :host {
      flex: 1 0 auto;
      text-align: right;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: var(--black-87);
      cursor: pointer;
      vertical-align: text-bottom;
    }
  `

  constructor () {
    super()
    this.url = new URL(window.location.href)
    this.title = document.title
    this.text = document.querySelector('meta[property="description"]')?.getAttribute('content')
  }

  render () {
    const shareData = this.getShareData()
    if (navigator.canShare !== undefined && navigator.canShare(shareData)) {
      return html`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
             @click="${this.onClick.bind(this)}">
          <path d="M352 224h-46.5c-45 0-81.5 36.5-81.5 81.5 0 22.3 10.3 34.3 19.2 40.5 6.8 4.7 12.8 12 12.8 20.3 0 9.8-8 17.8-17.8 17.8h-2.5c-2.4 0-4.8-.4-7.1-1.4C210.8 374.8 128 333.4 128 240c0-79.5 64.5-144 144-144h80V34.7C352 15.5 367.5 0 386.7 0c8.6 0 16.8 3.2 23.2 8.9l138.2 124.4c7.6 6.8 11.9 16.5 11.9 26.7s-4.3 19.9-11.9 26.7l-139 125.1c-5.9 5.3-13.5 8.2-21.4 8.2H384c-17.7 0-32-14.3-32-32v-64zM80 96c-8.8 0-16 7.2-16 16v320c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-48c0-17.7 14.3-32 32-32s32 14.3 32 32v48c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V112c0-44.2 35.8-80 80-80h48c17.7 0 32 14.3 32 32s-14.3 32-32 32H80z"/>
        </svg>
      `
    } else {
      console.debug('Web Share API not supported')
      return nothing
    }
  }

  getShareData () {
    return {
      title: this.title,
      text: this.text,
      url: getUrlWithTracking(this.url.toString(), {
        utm_source: 'share',
        utm_medium: 'social'
      })
    }
  }

  async onClick (event) {
    const shareData = this.getShareData()
    console.debug(shareData)
    try {
      await navigator.share(shareData)
    } catch (error) {
      // Ignore AbortError - caused by the user cancelling the share dialog
      if (error.name !== 'AbortError') {
        throw error
      }
    }
  }
}

window.customElements.define('ui-share', Share)
